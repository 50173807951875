/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import './src/styles/Layout.css';

import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;
