// sidebar
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_SIDEBAR_DOCKED = 'SET_SIDEBAR_DOCKED';

// sidebar content
export const ON_SIDEBAR_CONTENT_EXPAND = 'ON_SIDEBAR_CONTENT_EXPAND';

// initial visit
export const SET_INITIAL_VISIT_DONE = 'SET_INITIAL_VISIT_DONE';

// header
export const UPDATE_HEADER_HEIGHT = 'UPDATE_HEADER_HEIGHT';

// content
// export const SET_POST_PAGE_ON = "SET_POST_PAGE_ON";
// export const SET_POST_PAGE_OFF = "SET_POST_PAGE_OFF";

// anchor
export const SET_ANCHOR_OPEN = 'SET_ANCHOR_OPEN';
